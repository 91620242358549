@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --text-black: #232527;
  --text-white: #FFFFFF;
  --text-grey: #757575;

  --background-grey: #FAFAFA;
  --background-blue: #2A4858;

  --history-line-color: #38b238;
  --border-radius: 2px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: 'Inter', sans-serif;
}

*:focus {
  outline: none;
}

a, a:hover, a:visited, a:active {
  color: inherit;
}

html {
  background-color: #FFFFFF;
  overflow-y: scroll;
}

body {
  max-width: 1400px;
  margin:auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.TopMenu,
.TopSplash,
.SmallInfo,
.MainInfo,
.Footer {
  margin-left:32px;
  margin-right: 32px;
  position: relative;
}

.TopSplash,
.SmallInfo,
.MainInfo,
.Footer {
  z-index: 10;
}

.TopMenu {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 100px;
}

.TopMenu .Logo {
  height: 100%;
  cursor: pointer;
}

.TopMenu .Links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.TopMenu .Links .Link {
  color : var(--text-black);
  margin-left: 46px;

  font-size: 20px;
  line-height: 36px;
  font-weight: 500;

  cursor: pointer;
  user-select: none;
  margin-top: 8px;
  margin-bottom: 8px;
}

.Link:hover,
.Link.selected {
  border-bottom: 1px solid var(--text-black);
}

.TopSplash {
  height: calc(100vh - 100px - 150px);
  background-image: url("./top_splash.png");
  background-position:center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.TopSplash .Logo {
  width: 100%;
  max-width: 875px;
}

.TopSplash .Intro-Text {
  color: var(--text-white);

  font-size: 24px;
  font-weight: bold;

  border-bottom: 4px solid rgba(255, 255, 255, 0.8);

  margin-left: 32px;
  margin-right: 32px;

  padding-bottom: 12px;
  text-align: center;
}

.StyleArrow {
  position:absolute;
  max-width: 16px;
  left: 24px;
  top: 0px;
}

.StyleArrowBottom {
  position:absolute;
  max-width: 16px;
  right: 24px;
  bottom: 0px;
}

.SmallInfo {
  background: linear-gradient(88.98deg, #2A4858 14.06%, #30774A 53.43%, #38B238 100%);
  min-height: 150px;

  color: var(--text-white);
  font-weight: 300;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  padding: 32px;

  background: #38B238;
}

.SmallInfo .Title {
  color: var(--text-white);
  font-weight: 300;
  text-align: center;
  font-size: 24px;
}

.Row {
  display: flex;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.Strong {
  font-size: 24px;
  font-weight: bold;
}

.MainInfo {
  background-color: var(--background-grey);
  min-height: 100px;
  position: relative;
  color: var(--text-grey);

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;

  gap: 32px;
  padding: 64px 128px;
}

.MainInfo.blue {
  background-color: var(--background-blue);
  color: var(--text-white);
}

.MainInfo path {
  stroke: var(--text-grey);
}

.MainInfo.blue path {
  stroke: var(--text-white);
}

h1 {
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.05em;

  margin: 0px;
  
	color: var(--text-grey);
	font-size: 36px;
  text-align: left;
}

.MainInfo.blue h1 {
  color: var(--text-white);
	font-size: 40px;
}

h2 {
  font-style: normal;
  font-weight: 700;
  color: var(--text-grey);
  text-align: left;
}

.MainInfo.blue h2 {
  color: var(--text-white);
}

div {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-align: justify;
}

.MainInfo .Column {
  max-width: 512px;
  margin-bottom: 32px;
  width: 100%;
}

.MainInfo .Full {
  margin-bottom: 32px;
  width: 100%;
}

.Column img {
  width: 100%;
  user-select: none;
}

.Full img {
  width: 100%;
  margin: auto;
  user-select: none;
  display: block;

  max-width: 600px;
}

.Full video,
.Column video {
  width: 100%;
  max-height: 900px;
  object-fit: cover;
}

figcaption {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.36em;

  color: var(--text-black);
}

.blue figcaption {
  color: var(--text-white);
}

.Footer {
  background-color: var(--background-grey);
  text-align: center;
  padding: 16px;
}

#mce-EMAIL {
  width: 250px;
  margin: auto;
  color: var(--text-white);
  background-color: transparent;
  border: var(--text-white) solid 1px;
  border-radius: 2px;
  padding: 2px 12px;
  font-weight: 300;
  font-size: 16px;
}

#mce-EMAIL::placeholder {
  color: var(--text-white);
  font-weight: 300;
  font-size: 16px;
}

#mc-embedded-subscribe {
  color: var(--text-white);
  background-color: transparent;
  border: var(--text-white) solid 1px;
  border-radius: 2px;
  font-weight: 300;


  padding: 2px 12px;
  font-weight: 300;
  font-size: 16px;

  cursor: pointer;
}

#hiddenDiv {
  position: absolute;
  left: -5000px;
}

#mc_embed_signup_scroll {
  display: flex;
  gap: 8px;
}

.SmallInfo h2 {
  color: var(--text-white);
  margin: 0px;
  margin-top: 32px;
  margin-bottom: 16px;
}

.SmallInfo form {
  margin-bottom: 32px;
}

.SmallInfo .text {
  margin-bottom: 16px;
}

.note {
  font-size: 12px;
}

.PersonalInfo {
  background-color: var(--background-grey);
  width: 100%;
  color: var(--text-black);
  padding: 32px 64px;
  margin: 32px 0px;

  min-height: 530px;
}

.PersonalInfo h2 {
  color: var(--text-black) !important;
}

.PersonalInfo img {
  float: right;
  margin-left: 48px;
  margin-bottom: 16px;
  min-width: 128px;
  max-width: 256px;
  width: 30%;
  aspect-ratio: 4/5;
  object-fit: cover;
}

.PersonalInfo div {
  margin-bottom: 16px;
}

/* Downdown Start */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown, 
.dropdown * {
  z-index: 10000;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

  margin-top: 2px;
}

.dropdown-content div {
  color: var(--text-black);
  padding: 12px 18px;
  text-decoration: none;
  display: block;
  font-size: 18px;
  line-height: 24px;
}

.dropdown-content div:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

/* Downdown End */

/* Arrow */

.arrow {
  border: solid var(--text-black);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 6px;
  margin-bottom: 3px;
  margin-right: 4px;
}

.arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Arrow End */

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid var(--background-grey);
  text-align: center;
  padding: 24px 16px;
  color: var(--text-black);
}

td {
  font-weight: 500;
}

th {
  background-color: var(--background-blue);
  color: var(--text-white);
  font-weight: 700;
}

tr:nth-child(odd) {
  background-color: #ffffff;
}

tr:nth-child(even) {
  background-color: #eeeeee;
}

.tableBoxFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  columns: var(--text-black);
  font-weight: 500;
}

.tableBoxFlex span {
  margin-left: 8px;
}

.battery-table-configuration th,
.battery-table-configuration td {
  border: 1px solid var(--background-blue);
  padding: 12px 8px;
  text-align: center;
}

.battery-table-configuration tr:nth-child(1) {
  background-color: #ffffff;
  font-weight: 500;
  color: var(--text-black);
}

.battery-table-configuration tr:nth-child(2) td {
  background-color: var(--background-blue);
  color: var(--text-white);
  font-weight: 500;
}

.battery-table-configuration tr:nth-child(3) {
  background-color: #cccccc;
  color: var(--text-black);
  font-weight: 500;
}

.battery-table-configuration.special tr:nth-child(1) {
  background-color: gold;
  font-weight: 500;
  color: var(--text-black);
}

.tableBoxFlex svg path {
  color: var(--text-black);
  stroke: var(--text-black);
}

.NewsRelease {
  background-color: white;
  color: var(--text-black);
  padding: 32px 32px;
  margin: 8px 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.NewsRelease .NewsReleaseTitle {
  font-size: 20px;
  font-weight: 500;
  text-align: left;

  text-decoration: none;

  cursor: pointer;
}

.Button {
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;

  border-radius: 0px;
  color: var(--text-black) !important;
  outline: var(--text-grey) solid 2px;

  padding: 6px 16px;

  cursor: pointer;

  text-align: left;
  text-decoration: none;
}

.Button:hover {
  outline: var(--text-black) solid 4px;

}

.NewsRelease .NewsReleaseButton {
  font-size: 20px;
  font-weight: 500;

  border-radius: 0px;
  border: var(--text-black) solid 2px;

  padding: 4px 16px;

  margin-left: 24px;
  cursor: pointer;

  text-decoration: none;
}

.historyHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
}

.historyDate {
  font-style: normal;
  font-weight: 700;
  color: var(--text-grey);
  text-align: left;
  font-size: 20px;
}

.historyUpdate,
.historyStart {
  font-style: normal;
  font-weight: 700;
  color: var(--background-grey);
  text-align: left;
  font-size: 20px;
  border-radius: 4px;
  background-color: var(--history-line-color);

  padding: 12px 20px;

  text-align: center;

  position: relative;

  margin-bottom: 82px;
  margin-top: 64px;
}

.historyUpdate {
  margin-bottom: 64px;
  margin-top: 0px;
}

.histroyItem {
  position: relative;
}

.historyLine {
  position: absolute;
  width: 6px;
  height: 100%;
  height: -moz-available;          /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  height: fill-available;
  background-color: var(--history-line-color);
  left: calc(50% - 6px);
  border-radius: 4px;
}

.historyStart .historyLine {
  height: 450px;
}

.historyItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 100px;
  width: 100%;
}

.rightSide,
.leftSide {
  width: 50%;
}

.leftSide .historyText {
  margin-right: 38px;
}

.rightSide .historyDate,
.rightSide .historyText {
  margin-left: 32px;
}

.historyImageHolder {
  width: 100%;
  aspect-ratio: 16 / 9; 
  object-fit: cover;
}

.rightSide .historyImageHolder {
  margin-left: 32px;
  width: calc(100% - 32px);
}

.leftSide .historyImageHolder {
  margin-right: 38px;
  width: calc(100% - 38px);
}

.historyPoint {
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: var(--history-line-color);
  border-radius: 100%;

  left: calc(50% - 19px)
}

@media screen and (max-width: 1200px) {
  .TopMenu, .TopSplash, .SmallInfo, .MainInfo, .Footer {
    margin-left: 16px;
    margin-right: 16px;
  }

  .MainInfo {
    padding: 32px 64px;
  }
}


@media screen and (max-width: 900px) {
  .TopMenu, .TopSplash, .SmallInfo, .MainInfo, .Footer {
    margin-left: 8px;
    margin-right: 8px;
  }

  .MainInfo {
    padding: 16px 32px;
  }

  .StyleArrowBottom,
  .StyleArrow {
    display: none;
  }

  table {
    overflow-x: auto; display:block;
  }

  .TopMenu .Logo {
    height: 100%;
    max-height: 80px;
  }

  .TopMenu .Links .Link {
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 8px;
  }

  .TopMenu {
    height: unset;
  }
}

@media screen and (max-width: 700px) {

  .historyItem {
    flex-direction: column;
    align-items: center;
  }
  
  
  .leftSide .historyImageHolder,
  .rightSide .historyImageHolder,
  .leftSide .historyDate, 
  .leftSide .historyText,
  .rightSide .historyDate, 
  .rightSide .historyText {
    margin: 8px auto;
    width: 100%;
  }

  .rightSide,
  .leftSide {
    z-index: 100;
  }

  .historyPoint,
  .historyLine {
    display: none;
  }

  .historyStart, .historyUpdate,
  .rightSide, .leftSide {
    width: 100%;
  }

  .leftSide br, .rightSide br {
    display: none;
  }

  .historyItem.flipped {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 512px) {
  .TopMenu, .TopSplash, .SmallInfo, .MainInfo, .Footer {
    margin-left: 0px;
    margin-right: 0px;
  }

  .MainInfo {
    padding: 16px 16px;
  }

  .TopSplash {
    display: none;
  }

  .NewsReleaseButton {
    display: none;
  }

  .NewsRelease .NewsReleaseTitle {
    font-size: 16px;
    font-weight: 500;
    text-align: left !important;
    text-decoration: none;
    cursor: pointer;
  }

  .NewsRelease {
    padding: 16px 16px;
  }

  .NewsRelease {
    padding: 8px 8px;
  }

  .PersonalInfo {
    padding: 16px 16px;
  }

  .PersonalInfo img {
    width: 100%;
    margin: auto;
    margin-bottom: 8px;
    max-width: unset;
  }

  .TopMenu {
    height: unset;
    flex-direction: column;
    align-items: flex-start;
  }

  .TopMenu .Links .Link {
    margin-bottom: 8px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .TopMenu .Links {
    justify-content: flex-start;
  }
}